import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import logo from '../media/logo-inelvan2.png';


import WidgetsIcon from '@mui/icons-material/Widgets';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';

import { getCookie } from '../utils/cookies';

export const Header = () => {
    const Navigate = useNavigate();
    const token = getCookie('PHPSESSID');

    const cerrarSesion = async () => {


        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('code');
        localStorage.removeItem('mail');
        localStorage.removeItem('DesRepresentante');
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


        Navigate("/login");

        try {
            const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
                'token': token,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Respuesta del servidor:', response.data);


        } catch (error) {
            console.error('Error al invalidar el token', error);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            // className='bordes-redondos sombra-mini'
        >
            <Grid item xs={12} md={2} onClick={() => Navigate("/")} className='pointer no-select'>
                <img src={logo} alt="" style={{ maxWidth: '50%', margin: '1em 0em' }} />
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                xs={12} md={10}
            >
                {/* <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/")} fullWidth> <WidgetsIcon sx={{ fontSize: 30, m: 1 }} color='azul' /> Inicio</Button>
                </Grid> */}
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/crear-trabajo")} fullWidth> <GroupAddIcon sx={{ fontSize: 30, m: 1 }} color='azul' />Crear Trabajo</Button>
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/trabajos")} fullWidth> <GroupIcon sx={{ fontSize: 30, m: 1 }} color='negro' />Ver Trabajos</Button>
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/editar-stock")} fullWidth> <AutoFixHighIcon sx={{ fontSize: 30, m: 1 }} color='naranja' />Editar Stock</Button>
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/anadir-articulo")} fullWidth> <LibraryAddIcon sx={{ fontSize: 30, m: 1 }} color='verde' />Añadir Articulo</Button>
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => Navigate("/ver-stock")} fullWidth> <WarehouseIcon sx={{ fontSize: 30, m: 1 }} color='action' />Ver Stock</Button>
                </Grid>
                <Grid item xs={5} md={2}>
                    <Button variant="text" color='azul' size="large" className='boton-header' onClick={() => cerrarSesion()} fullWidth> <LogoutIcon sx={{ fontSize: 30, m: 1 }} color='azul' /> Salir</Button>
                </Grid>
            </Grid>
            <div className='separador-2'></div>
        </Grid>

    )
}
