import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CancelIcon from '@mui/icons-material/Cancel';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';


import { Header } from '../components/Header';
import { postTrabajos } from '../services/apis';
import { Notificacion } from '../components/Notificacion';

export const CrearTrabajo = () => {
    // Crear un evento de teclado para la tecla "Tab"
    let tecla = new KeyboardEvent('keydown', {
        key: 'Tab',
        code: 'Tab',
        keyCode: 9,        // keyCode para Tab
        charCode: 9,
        which: 9,
        bubbles: true // Hacer que el evento burbujee
    });

    const Navigate = useNavigate();
    const firstInputRef = useRef(null);
    const secondInputRef = useRef(null);


    const [notificacion, setNotificacion] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [severity, setSeverity] = useState("success")

    const [cantidad, setCantidad] = useState(1);
    const [codBarras, setCodBarras] = useState("");
    const [articulos, setArticulos] = useState([]);
    const [articulo, setArticulo] = useState(
        {
            nombre: "Articulo ",
            codBarras: codBarras,
            cantidad: cantidad
        });
    const [nombreTr, setNombreTr] = useState("")
    const [finalizar, setFinalizar] = useState(false);

    const [openCamara, setOpenCamara] = useState(false);
    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);
    const [timer, setTimer] = useState(null);


    const handledOnChange = (event) => {

        switch (event.target.name) {
            case "barcode":
                setCodBarras(event.target.value);
                // Cancelar el temporizador existente si hay alguno
                if (timer) {
                    clearTimeout(timer);
                }

                // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
                setTimer(setTimeout(() => {
                    secondInputRef.current.focus();
                }, 500));
                break;

            case "cantidad":
                if (event.target.value <= 0) {
                    setCantidad(1)
                    break;
                } else {
                    setCantidad(event.target.value);
                    break;
                }

            case "nombre":
                setNombreTr(event.target.value);
                break;

            default:
                break;

        }
    };

    const handleClose = () => {
        setFinalizar(false);
    };

    const abrirCamara = (openCamara, camara) => {

        if (openCamara == false && camara == false) {
            setOpenCamara(true);
            setCamara(true);
        } else {
            setOpenCamara(false);
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                setCamara(false);

            }, 2000));
        }

    };

    const añadirArticulo = (articulo, cantidad, codBarras) => {
        cantidad = parseInt(cantidad);
        if (codBarras !== "") {
            setArticulos((prevArticulos) => {
                // Buscar si ya existe un artículo con el mismo codBarras
                const articuloExistente = prevArticulos.find((item) => item.codBarras === codBarras);

                if (articuloExistente) {
                    // Si ya existe, sumar la cantidad
                    return prevArticulos.map((item) =>
                        item.codBarras === codBarras
                            ? { ...item, cantidad: item.cantidad + cantidad } // Actualizar cantidad
                            : item
                    );
                } else {
                    // Si no existe, añadir el nuevo artículo
                    const nuevoArticulo = { ...articulo, cantidad, codBarras };
                    return [...prevArticulos, nuevoArticulo];
                }
            });
            setCantidad(1);
            setCodBarras('');
            // Cancelar el temporizador existente si hay alguno
            if (timer) {
                clearTimeout(timer);
            }

            // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
            setTimer(setTimeout(() => {
                firstInputRef.current.focus();
            }, 250));
            setMensaje("Articulo añadido");
            setSeverity("success")
            activarNotificacion();

        } else {
            setMensaje("Introduzca un codigo de barras");
            setSeverity("error")
            activarNotificacion();
        }

    };

    const borrarArticulo = (codBarras) => {
        // Filtrar el array para eliminar solo el artículo con el codBarras dado
        const nuevosArticulos = articulos.filter(articulo => articulo.codBarras !== codBarras);

        // Actualizar el estado con el nuevo array filtrado
        setArticulos(nuevosArticulos);
    }

    const checkArray = (arr) => {
        if (arr.length < 1) {
            setMensaje("Añade algun articulo para crear la orden de trabajo");
            setSeverity("error")
            activarNotificacion();

        } else {
            if (nombreTr == "" || nombreTr == null) {
                setMensaje("La orden de trabajo debe de tener un nombre");
                setSeverity("error")
                activarNotificacion();
                setFinalizar(false)


            } else {
                setFinalizar(true)
            }
        }
    }

    const crearTrabajo = async (arrArticulos) => {

        try {
            const response = await postTrabajos(arrArticulos, nombreTr);
            // console.log("Respuesta", response)
            setFinalizar(false)
            Navigate("/trabajos")

        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }
    }

    const activarNotificacion = () => {
        // setMensaje(texto);
        // Cambiar la notificación a true
        setNotificacion(true);

        // Después de 2 segundos, volverla a false
        setTimeout(() => {
            setNotificacion(false);
        }, 3000);
    };
    // Configuración de la cámara
    const videoConstraints = {
        facingMode: "environment", // Usar la cámara trasera
    };

    // Método para capturar la imagen de la cámara
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            scanBarcode(imageSrc); // Intentar escanear el código de barras
        }

        // Cancelar el temporizador existente si hay alguno
        if (timer) {
            clearTimeout(timer);
        }

        // Establecer un nuevo temporizador para ejecutar la función después de 2000ms (2 segundos)
        setTimer(setTimeout(() => {
            secondInputRef.current.focus();
        }, 250));
    }, [webcamRef]);

    // Método para escanear el código de barras
    const scanBarcode = async (imageSrc) => {
        setIsScanning(true);
        const reader = new BrowserMultiFormatReader();
        try {
            const result = await reader.decodeFromImageUrl(imageSrc);
            setCodBarras(result.getText());
        } catch (err) {
            console.error("Error escaneando el código de barras:", err);
            setCodBarras(null);
        }
        setIsScanning(false);
    };


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
        >
            <Header></Header>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <GroupAddIcon sx={{ fontSize: 50, m: 1 }} color='azul' />
                        <Typography variant="h4">
                            Crear Trabajo
                        </Typography>
                    </Grid>

                    <div className="separador"></div>

                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    md={11}
                    spacing={1}
                >
                    <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Añadir articulo
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12}>
                            <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => abrirCamara(openCamara, camara)} style={{ margin: '1em 0em', padding: '0.7em' }}>
                                <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                            </Button>
                        </Grid>
                        <Collapse in={openCamara}>
                            {camara ? <div>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    style={{ width: "100%", height: "auto" }}
                                    className='borde-redondo'
                                />
                                <Button color='azul' variant="contained" size="large" fullWidth onClick={capture} style={{ padding: '0.7em' }}> <QrCodeScannerIcon fontSize='large' />&nbsp;Escanear codigo </Button>

                                <div className="separador-2" style={{ margin: '1em 0em' }}></div>
                            </div>
                                : ''}

                        </Collapse>


                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Codigo de Barras"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    autoFocus
                                    required
                                    onChange={handledOnChange}
                                    value={codBarras}
                                    name='barcode'
                                    inputRef={firstInputRef}

                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad'
                                    value={cantidad}
                                    onChange={handledOnChange}
                                    inputRef={secondInputRef}
                                />

                            </Grid>
                            <Button
                                color='azul'
                                variant="contained"
                                size="large"
                                fullWidth
                                style={{ marginTop: '1em', marginBottom: '1em', padding: '1em' }}
                                onClick={() => añadirArticulo(articulo, cantidad, codBarras)}
                            >
                                <AddBoxIcon color='blanco' />&nbsp;Añadir articulo
                            </Button>
                        </FormControl>

                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        md={6}
                        gap={1}
                        style={{ maxHeight: '55vh', overflow: 'auto' }}
                    >
                        {articulos.length > 0 ? (((
                            articulos.map((info, index) => (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    xs={10} md={11}
                                >
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                                            {info.nombre + (index + 1)}
                                        </Typography>
                                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                                            Cod: {info.codBarras}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                        <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
                                            Cantidad: {info.cantidad}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <CancelIcon className='pointer no-select' color='disabled' onClick={() => borrarArticulo(info.codBarras)} />
                                    </Grid>

                                    <div className="separador"></div>
                                </Grid>

                            ))

                        ))) : ((<Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            xs={12} md={12}
                        >
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" style={{ textAlign: 'left' }}>
                                    No hay articulos
                                </Typography>
                            </Grid>
                        </Grid>
                        ))}
                    </Grid>

                    <div className="separador-2"></div>

                    <div className="separador"></div>

                    <Grid item xs={12} style={{ marginTop: '1em' }}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Nombre de trabajo"
                            variant="outlined"
                            color='azul'
                            type='text'
                            name='nombre'
                            value={nombreTr}
                            onChange={handledOnChange}
                            required
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="outlined" size="large" fullWidth className='boton-header' onClick={() => Navigate("/")} style={{ minHeight: '6vh', fontSize: 'large' }}> Cancelar </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="contained" size="large" fullWidth className='boton-general' onClick={() => checkArray(articulos)}><GroupAddIcon color='blanco' />&nbsp;Crear trabajo </Button>
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>



                {/* ---------------------------------------------------------------------------NOTIFICACIONES Y DIALOGO----------------------------------------------------------------------------- */}

                <Notificacion notificacion={notificacion} mensaje={mensaje} severity={severity}></Notificacion>


                <Dialog
                    open={finalizar}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className='fondo-blanco'
                    >
                        {"Crear Trabajo"}
                    </DialogTitle>
                    <DialogContent
                        className='fondo-blanco'
                    >
                        <DialogContentText id="alert-dialog-description">
                            Desea crear la orden de trabajo?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        className='fondo-blanco'
                    >
                        <Button onClick={handleClose} color='azul' size="medium" variant="outlined">Cancelar</Button>
                        <Button onClick={() => crearTrabajo(articulos)} autoFocus color='azul' variant="contained" size="medium">
                            Crear Trabajo
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    )
}
