import React from 'react'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const Notificacion = ({ notificacion, mensaje, severity }) => {
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={notificacion}
                // onClose={handleClose}
                message={mensaje}
                key={{ vertical: 'top', horizontal: 'center' }}
                severity={severity}
            >
                <Alert
                    // onClose={handleClose}
                    severity={severity}
                    color='naranja'
                    // className='fondo-blanco'
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {mensaje}
                </Alert>
            </Snackbar>
        </div>
    )
}
