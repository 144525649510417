import axios from "axios";
import { getCookie } from "../utils/cookies";


const baseUrl = 'https://www.inelvan.com/stockapp/WS/'

// ----------------------------------------------------------TRABAJOS------------------------------------------
// --------------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------GET------------------------------------------
export const getTrabajos = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                // idCliente: idCliente
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const getTrabajosRecientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                recientes: 1
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getEditarTrabajo = async (idTrabajo) => {
    try {
        const response = await axios.get(baseUrl + 'trabajos.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                idTrabajo: idTrabajo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } else {
            console.log('No se pudo verificar el token', response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// ----------------------------------------------------------POST------------------------------------------
export const postTrabajos = async (data, nombre) => {
    try {
        const response = await axios.post(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: data,
            nombre: nombre
        });
        // console.log("aaaa", response);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

// ----------------------------------------------------------PUT------------------------------------------
export const finalizarTrabajo = async (idTrabajo, articulos, horas) => {
    try {
        console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB", articulos)

        const response = await axios.put(baseUrl + 'trabajos.php?', {
            token: getCookie('PHPSESSID'),
            data: articulos,
            idTrabajo: idTrabajo,
            horas: horas
        });
        console.log("aaaa", response.success);

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error.response);
        return null;
    }
};