import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import logo from '../media/logo.png';


export const Login = ({ username, setUsername, password, setPassword }) => {
    const Navigate = useNavigate();
    const wsUrl = "www.inelvan.com/stockapp";
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };
    const handleLogin = async () => {
        try {
            const response = await axios.post(`https://${wsUrl}/WS/login.php`, {
                username: username,
                password: password,
            });
            if (response.data.success) {
                // Guarda el token en una cookie con una fecha de expiración
                const tokenExpiryDate = new Date();
                tokenExpiryDate.setTime(tokenExpiryDate.getTime() + (12 * 60 * 60 * 1000)); // 12 horas
                document.cookie = `PHPSESSID=${response.data.token}; path=/; expires=${tokenExpiryDate.toUTCString()};`;

                // Guarda otros datos en el almacenamiento local
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.nombre);
                setPassword('');
                setUsername('');

                Swal.fire({
                    icon: "success",
                    title: `Bienvenido/a \n${username}`,
                    showConfirmButton: false,
                    timer: 1500
                });
                Navigate('/');
            } else {
                Swal.fire({
                    icon: "error",
                    title: `Error de inicio de sesión: \n${response.data.message}`,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            style={{ minHeight: '100vh' }}
            className='load'
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={3}
                className='borde-redondo sombra-corta'
                style={{ marginTop: '10vh'}}
            >
                <Grid item xs={12} md={1}>
                    <img src={logo} alt="" style={{ maxWidth: '50%', marginTop: '2em' }} />
                </Grid>

                <Typography variant="h3" gutterBottom>
                    Iniciar sesión
                </Typography>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    xs={10} md={8}
                >
                    <div className='separador'></div>
                    <Typography variant="h5" gutterBottom>
                        Usuario
                    </Typography>

                    <TextField name='username' id="outlined-basic" label="Usuario" variant="outlined" color='azul' style={{ minWidth: '100%' }} onChange={(e) => setUsername(e.target.value)} onKeyPress={handleKeyPress}/>

                    <div className="separador-2"></div>

                    <Typography variant="h5" gutterBottom>
                        Contraseña
                    </Typography>

                    <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul' onKeyPress={handleKeyPress}>
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            color='azul'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color='azul'
                        size="large"
                        style={{ margin: '2em 0em', minWidth: '100%', minHeight: '6vh', fontSize: 'large' }}
                        onClick={() => handleLogin()}
                    >
                        Iniciar sesión
                    </Button>
                </Grid>


            </Grid>

        </Grid>
    )
}
