import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';


import GroupIcon from '@mui/icons-material/Group';

import { Header } from '../components/Header';
import { getEditarTrabajo } from '../services/apis';

export const TrabajoFinalizado = () => {
    const param = useParams();
    const Navigate = useNavigate();

    const [articulos, setArticulos] = useState([]);
    const [trabajo, setTrabajo] = useState([]);


    const getTrabajo = async (idTrabajo) => {
        try {
            const response = await getEditarTrabajo(idTrabajo);
            console.log("Respuesta", response)
            setArticulos(response.data)
            setTrabajo(response.info[0]);

        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }
    }

    useEffect(() => {
        getTrabajo(param.idTrabajo);
    }, [])


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
        >
            <Header></Header>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                className='borde-redondo sombra-corta'
                padding={2}
                gap={1}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={11} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <GroupIcon sx={{ fontSize: 50, m: 1 }} color='negro' />
                        <Typography variant="h4" style={{ textAlign: 'left' }}>
                            Trabajo Nº: {param.idTrabajo}
                        </Typography>
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={11}
                >

                    <Grid item xs={12} md={5}>
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            {trabajo.nombre}
                        </Typography>
                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Horas: {trabajo.horas}h
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography variant="h6" style={{ textAlign: 'right' }}>
                            Fecha creacion: {trabajo.fecha}
                        </Typography>
                        <Typography variant="h6" style={{ textAlign: 'right' }}>
                            Creado por: Ivan
                            {/* {trabajo.idUsuario} */}
                        </Typography>
                    </Grid>

                    <div className="separador"></div>


                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Articulos usados
                        </Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: '1em' }}>
                        <div className="separador-azul"></div>
                    </Grid>



                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                    gap={1}
                style={{ maxHeight: '50vh', overflow: 'auto', padding: '1em' }}
                // className='fondo-azul borde-redondo sombra-interior'
                >
                    {articulos.map((info, index) => (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            md={12}
                            gap={2}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                md={12}
                                gap={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <Typography variant="h5" style={{ textAlign: 'left' }}>
                                        {info.nombre}
                                    </Typography>
                                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                                        Cod: {info.codBarras}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle1" style={{ textAlign: 'right' }}>
                                        Cantidad: {info.cantidad}
                                    </Typography>
                                </Grid>
                            </Grid>


                            <div className="separador"></div>

                        </Grid>
                    ))}
                </Grid>





            </Grid>
        </Grid>
    )
}
