import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Inicio } from '../pages/Inicio'
import { Login } from '../pages/Login';
import { VerStock } from '../pages/VerStock';
import { EditarStock } from '../pages/EditarStock';
import { AnadirStock } from '../pages/AnadirStock';
import { CrearTrabajo } from '../pages/CrearTrabajo';
import { Trabajos } from '../pages/Trabajos';
import { EditarTrabajo } from '../pages/EditarTrabajo';

import PrivateRoute from './PrivateRoute';
import { TrabajoFinalizado } from '../pages/TrabajoFinalizado';


export const Router = () => {

    const theme = createTheme({
        palette: {
            azul: {
                main: '#2e5ca2',
                light: '#2e5ca2',
                dark: '#2e5ca2',
                contrastText: '#EEEEEE'
            },
            naranja: {
                main: '#F9B234',
                light: '#F9B234',
                dark: '#F9B234',
                contrastText: '#111111'
            },
            blanco: {
                main: '#EEEEEE',
                light: '#EEEEEE',
                dark: '#EEEEEE',
                contrastText: '#111111'
            },
            negro: {
                main: '#111111',
                light: '#111111',
                dark: '#111111',
                contrastText: '#FFFFFF'
            },
            verde: {
                main: '#33b13d',
                light: '#33b13d',
                dark: '#33b13d',
                contrastText: '#000000'
            }
        }
    });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div className='load' style={{ minHeight: '100vh' }}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<PrivateRoute />}>
                            <Route path='*' element={<Inicio />} ></Route>
                            <Route path='/' element={<Inicio />} ></Route>
                            <Route path='/inicio' element={<Inicio />} ></Route>
                            <Route path='/crear-trabajo' element={<CrearTrabajo />} ></Route>
                            <Route path='/trabajos' element={<Trabajos />} ></Route>
                            <Route path='/editar-trabajo/:idTrabajo' element={<EditarTrabajo />} ></Route>
                            <Route path='/trabajo-finalizado/:idTrabajo' element={<TrabajoFinalizado />} ></Route>
                            <Route path='/ver-stock' element={<VerStock />} ></Route>
                            <Route path='/editar-stock' element={<EditarStock />} ></Route>
                            <Route path='/anadir-articulo' element={<AnadirStock />} ></Route>
                        </Route>

                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path='/login' element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />} ></Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>

        </div>
    )
}
