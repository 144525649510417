import React, { useState, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';


import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SearchIcon from '@mui/icons-material/Search';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CameraAltIcon from '@mui/icons-material/CameraAlt';



import { Header } from '../components/Header';

export const EditarStock = () => {
    const Navigate = useNavigate();

    const [articulo, setArticulo] = useState(
        {
            // nombre: "Articulo de ejemplo 1",
            // codigoBarras: "5643210-12312412343654",
            // codigo: "756442365",
            // referencia: "2423663-89962121",
            // stock: 60
        });
    const [camara, setCamara] = useState(false);

    const [barcode, setBarcode] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);

    // Configuración de la cámara
    const videoConstraints = {
        facingMode: "environment", // Usar la cámara trasera
    };

    // Método para capturar la imagen de la cámara
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            scanBarcode(imageSrc); // Intentar escanear el código de barras
        }
    }, [webcamRef]);

    // Método para escanear el código de barras
    const scanBarcode = async (imageSrc) => {
        setIsScanning(true);
        const reader = new BrowserMultiFormatReader();
        try {
            const result = await reader.decodeFromImageUrl(imageSrc);
            setBarcode(result.getText());
        } catch (err) {
            console.error("Error escaneando el código de barras:", err);
            setBarcode(null);
        }
        setIsScanning(false);
    };




    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
        >
            <Header></Header>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <AutoFixHighIcon sx={{ fontSize: 50, m: 1 }} color='naranja' />
                        <Typography variant="h4">
                            Editar Stock
                        </Typography>
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="start"
                    md={11}
                    spacing={1}
                >
                    {/* <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Articulo:&nbsp;
                            {articulo.nombre}
                        </Typography>
                    </Grid> */}
                    <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Codigo de Barras
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ marginBottom: '1em', padding: '0.9em' }}>
                            <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                        </Button>
                        <Collapse in={camara}>
                            {camara ? <div>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    style={{ width: "100%", height: "auto" }}
                                    className='borde-redondo'
                                />
                                <Button color='azul' variant="contained" size="large" fullWidth onClick={capture} style={{ padding: '0.7em' }}> <QrCodeScannerIcon fontSize='large' />&nbsp;Escanear codigo </Button>
                            </div>
                                : ''}

                        </Collapse>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                            <InputLabel htmlFor="outlined-adornment-password">Codigo de Barras</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                color='azul'
                                name='buscar'
                                type="text"
                                value={barcode}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            // onClick={console.log("buscar")}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Codigo de Barras"
                            />
                        </FormControl>
                        <div className="separador-2" style={{ margin: '1em 0em' }}></div>

                        <Grid item xs={12} md={12}>
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Codigo de referencia/ariculo
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl sx={{ minWidth: '100%', marginBottom: '0.5em' }} variant="outlined" color='azul'>
                                <InputLabel htmlFor="outlined-adornment-password">Codigo de referencia</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    color='azul'
                                    name='buscar'
                                    type="text"
                                    value={articulo.referencia}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                // onClick={console.log("buscar")}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Codigo de referencia"
                                />
                            </FormControl>
                        </Grid>
                        <div className="separador-2"></div>
                        <Grid item xs={12} md={12}>
                            <FormControl sx={{ minWidth: '100%', marginTop: '0.5em' }} variant="outlined" color='azul'>
                                <InputLabel htmlFor="outlined-adornment-password">Codigo de ariculo</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    color='azul'
                                    name='buscar'
                                    type="text"
                                    value={articulo.codigo}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                // onClick={console.log("buscar")}
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Codigo de ariculo"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="h5" style={{ textAlign: 'left', marginTop: '1em' }}>
                                Cantidad
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Cantidad"
                                variant="outlined"
                                color='azul'
                                type='number'
                                value={articulo.stock}
                            />

                        </Grid>
                    </Grid>





                    <div className="separador-2"></div>

                    <div className="separador"></div>

                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="outlined" size="large" fullWidth className='boton-header' onClick={() => Navigate("/")} style={{ minHeight: '6vh', fontSize: 'large' }}> Cancelar </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button color='azul' variant="contained" size="large" fullWidth className='boton-general'> Cambiar Stock </Button>
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>


            </Grid>
        </Grid>
    )
}
