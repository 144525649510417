import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import SearchIcon from '@mui/icons-material/Search';
import WarehouseIcon from '@mui/icons-material/Warehouse';

import { Header } from '../components/Header';
import { BtnAtras } from '../components/BtnAtras'

function valuetext(value) {
    return `${value}°C`;
}
export const VerStock = () => {
    const Navigate = useNavigate();

    const [value, setValue] = React.useState([25, 75]);
    const [age, setAge] = React.useState('');

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [articulos, setArticulos] = useState([
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        },
        {
            nombre: "Articulo 1",
            codigo: "123431",
            referencia: "1123123-123123",
            stock: 60
        }

    ])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
            xs={12}
            className='load'
            gap={2}
        >
            <Header></Header>


            {/* ------------------------------------FLTROS--------------------------------- */}
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                md={2}
                gap={2}
                className='borde-redondo sombra-corta'
                padding={2}
            >
                <Typography variant="h4">
                    Filtros:
                </Typography>

                <div className="separador"></div>

                <Typography variant="subtitle1">
                    Buscar
                </Typography>
                <FormControl sx={{ minWidth: '100%' }} variant="outlined" color='azul'>
                    <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        color='azul'
                        name='buscar'
                        type="text"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    // onClick={console.log("buscar")}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Buscar"
                    />
                </FormControl>

                <Typography variant="subtitle1">
                    Filtro de articulos 1
                </Typography>
                <Slider
                    getAriaLabel={() => 'Stock range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    color='azul'
                />

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={12}
                // gap={4}
                >
                    <Grid item xs={12} md={3}>
                        <TextField id="outlined-basic" label="Min" variant="outlined" value={value[0]} type='number'/>
                    </Grid>
                    <Typography variant="subtitle1">
                        Stock
                    </Typography>
                    <Grid item xs={12} md={3}>
                        <TextField id="outlined-basic" label="Max" variant="outlined" value={value[1]} type='number'/>
                    </Grid>
                </Grid>

                <Typography variant="subtitle1">
                    Filtro de articulos 3
                </Typography>
                <FormControlLabel control={<Switch color='azul' />} label="Filtro Switch" />
                <FormControlLabel control={<Switch color='azul' defaultChecked />} label="Filtro Switch" />
                <FormControlLabel control={<Switch color='azul' />} label="Filtro Switch" />

                <Typography variant="subtitle1">
                    Filtro de articulos 4
                </Typography>
                <FormControlLabel control={<Checkbox color='azul' defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />
                <FormControlLabel control={<Checkbox color='azul' sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />
                <FormControlLabel control={<Checkbox color='azul' defaultChecked sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }} />} label="Filtro Checkbox" />

            </Grid>



            {/* ------------------------------------ARTICULOS--------------------------------- */}

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                md={9}
                // className='borde-redondo sombra-corta'
                padding={2}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    md={12}
                >
                    <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <WarehouseIcon sx={{ fontSize: 50, m: 1 }} color='azul' />
                        <Typography variant="h4">
                            Articulos
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Ordenar por...</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Ordenar por..."
                                onChange={handleChange2}
                            >
                                <MenuItem value={10}>Por defecto</MenuItem>
                                <MenuItem value={20}>De alfabetico ascendente</MenuItem>
                                <MenuItem value={30}>De alfabetico descendente</MenuItem>
                                <MenuItem value={40}>Por stock ascendente</MenuItem>
                                <MenuItem value={50}>Por stock descendente</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <div className="separador"></div>
                <div className="separador-2"></div>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={12}
                    gap={2}
                >
                    {articulos.map((info, index) => (
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            md={3.5}
                            className='borde-redondo sombra-mini pointer card-articulo'
                            padding={3}
                        >
                            <Typography variant="h5" gutterBottom fontWeight={500}>
                                {info.nombre}
                            </Typography>
                            <div className="separador-azul"></div>
                            {/* <Typography variant="subtitle2" gutterBottom>
                               Info:
                            </Typography> */}
                            <Typography variant="body1" gutterBottom>
                                Codigo: {info.codigo}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Referencia: {info.referencia}
                            </Typography>

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                md={12}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Stock: {info.stock}
                                </Typography>
                                <Button variant="contained" color='azul' className='boton-editar' onClick={() => Navigate("/editar-stock")}>Editar</Button>
                            </Grid>

                        </Grid>
                    ))}

                </Grid>

            </Grid>
        </Grid>
    )
}
