import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


import { Header } from '../components/Header';

export const AnadirStock = () => {
  const Navigate = useNavigate();


  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
      xs={12}
      className='load'
      gap={2}
    >
      <Header></Header>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={11} md={8}
        className='borde-redondo sombra-corta'
        padding={2}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          md={11}
        >
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <LibraryAddIcon sx={{ fontSize: 50, m: 1 }} color='verde' />
            <Typography variant="h4">
              Añadir Articulo
            </Typography>
          </Grid>


          <div className="separador"></div>
        </Grid>


        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          xs={12} md={11}
          spacing={1}
        >
          <div className="separador-2"></div>
          <Grid item xs={12} >
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Nombre del articulo:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField id="outlined-basic" label="Nombre del articulo" variant="outlined" fullWidth color='azul' />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Codigo de barras
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" size="large" fullWidth> <QrCodeScannerIcon fontSize='large' /> </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField id="outlined-basic" label="Codigo de barras" variant="outlined" fullWidth color='azul' />
          </Grid>
          <div className="separador-2"></div>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Codigo de referencia
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField id="outlined-basic" label="Codigo" variant="outlined" fullWidth color='azul' />
          </Grid>
          <div className="separador-2"></div>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'left' }}>
              Stock inicial
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField id="outlined-basic" label="Cantidad" variant="outlined" fullWidth color='azul' type='number' />
          </Grid>
          <div className="separador-2"></div>
          <div className="separador"></div>
          <Grid item xs={12} md={6}>
            <Button variant="outlined" size="large" fullWidth className='boton-header boton-general' onClick={() => Navigate("/")}> Cancelar </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="contained" size="large" fullWidth className='boton-general'> Añadir Stock </Button>
          </Grid>
          <div className="separador-2"></div>


        </Grid>
      </Grid>
    </Grid>
  )
}
