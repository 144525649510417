import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';

import logo from '../media/logo-inelvan2.png';
import { ListaTrabajos } from '../components/ListaTrabajos';
import { getCookie } from '../utils/cookies';
import { getTrabajosRecientes } from '../services/apis';



export const Inicio = () => {
    const Navigate = useNavigate();

    let tamIco = 80;
    const token = getCookie('PHPSESSID');
    const [trabajos, setTrabajos] = useState([]);

    const getTrabajo = async () => {
        try {
            const response = await getTrabajosRecientes();
            console.log("aa", response)
            setTrabajos(response.data)
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }
    const cerrarSesion = async () => {


        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('code');
        localStorage.removeItem('mail');
        localStorage.removeItem('DesRepresentante');
        document.cookie = "PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


        Navigate("/login");

        try {
            const response = await axios.post('https://compras.clasol.com/WS/Logout.php', {
                'token': token,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Respuesta del servidor:', response.data);


        } catch (error) {
            console.error('Error al invalidar el token', error);
        }
    };


    useEffect(() => {
        getTrabajo();
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load fondo-blanco'
        >
            {/* <Header></Header> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                // gap={4}
                className='margen-top'
            >
                <Grid item xs={10} md={2.5}
                    // onClick={() => Navigate("/")} 
                    className='no-select'
                >
                    <img src={logo} alt="" style={{ maxWidth: '100%' }} />
                </Grid>

                <Grid container xs={11}>
                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    gap={2}
                    style={{ marginBottom: '2em', marginTop: '1em' }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/crear-trabajo")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <GroupAddIcon sx={{ fontSize: tamIco }} color='azul' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Crear Trabajo
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Crear parte de trabajo
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/trabajos")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <GroupIcon sx={{ fontSize: tamIco }} color='negro' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Ver/Editar Trabajos
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Crear parte de trabajo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/editar-stock")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <AutoFixHighIcon sx={{ fontSize: tamIco }} color='naranja' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Editar Stock
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Editar el stock de un articulo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={5}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/anadir-articulo")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} style={{ marginRight: 10 }}>
                            <LibraryAddIcon sx={{ fontSize: tamIco }} color='verde' />
                        </Grid>
                        <Grid container xs={8} md={6}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Añadir Articulo
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Añadir un nuevo articulo
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={11} md={10.15}
                        className='borde-redondo sombra-corta pointer card-articulo'
                        onClick={() => Navigate("/ver-stock")}
                        padding={2}
                        gap={3}
                    >
                        <Grid container xs={2} md={0.95} style={{ marginRight: 10 }}>
                            <WarehouseIcon sx={{ fontSize: tamIco }} color='action' />
                        </Grid>
                        <Grid container xs={8} md={8}
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography variant="h6" gutterBottom>
                                Ver Stock
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom className='texto-gris'>
                                Ver el stock actual
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11}
                    style={{ marginBottom: '1em' }}
                >
                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                        Trabajos recientes
                    </Typography>
                    <div className="separador"></div>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                xs={11} md={11}
                style={{ overflow: 'auto', maxHeight: '40vh' }}
                padding={2}
            >
                <ListaTrabajos trabajos={trabajos}></ListaTrabajos>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                xs={9.5} md={11}
                style={{ margin: '1em 0em' }}
            >
                <Tooltip title="Cerrar sesión">
                    <Fab color='azul' aria-label="Cerrar sesión">
                        <LogoutIcon onClick={() => cerrarSesion()} />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>

    )
}
