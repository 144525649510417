import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';


import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const ListaTrabajos = ({ trabajos, finalizado }) => {
    const Navigate = useNavigate();

    const cambiarFormatoFecha = (fechaCompleta) => {
        // Dividimos la parte de la fecha y la hora
        const [fechaISO, hora] = fechaCompleta.split(' ');

        // Dividimos la fecha ISO en [año, mes, día]
        const [year, month, day] = fechaISO.split('-');

        // Creamos el nuevo formato de fecha "día/mes/año"
        const fechaFormateada = `${day}/${month}/${year}`;

        // Retornamos el nuevo formato completo: "fecha ISO fecha formateada - hora"
        return `${fechaFormateada} - ${hora}`;
    };


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                gap={2}
            >
                {trabajos.map((info, index) => (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        xs={12} md={3}
                        className='borde-redondo sombra-corta card-articulo load'
                        padding={2}
                        key={index}
                    >
                        <Grid item xs={info.finalizado == 1 ? 10 : 12}>
                            <Typography variant="h5" style={{ textAlign: 'left', marginBottom: '0.5em', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {info.nombre}
                            </Typography>
                        </Grid>
                        {info.finalizado == 1 ?
                            <Grid item xs={2} className='no-select'>
                                <Tooltip
                                    title="Finalizado"
                                    placement="top" color='error'
                                    arrow
                                    TransitionComponent={Zoom}
                                >
                                    <CheckCircleIcon color='success' fontSize='medium' />
                                </Tooltip>
                            </Grid>
                            : ''}
                        <div className="separador-azul" style={{ marginBottom: '0.5em' }}></div>

                        <Grid item xs={12} style={{ textAlign: 'left', marginBottom: '0.5em' }}>
                            <Typography variant="caption" style={{ textAlign: 'left', marginBottom: '1em', fontWeight: '400' }}>
                                Fecha: {cambiarFormatoFecha(info.fecha)}
                            </Typography>
                        </Grid>
                        <Grid container xs={12} justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" style={{ textAlign: 'left' }}>
                                Trabajo Nº{info.idTrabajo}
                            </Typography>
                            {info.finalizado == 0
                                ? <Button variant="contained" color='azul' className='boton-editar' onClick={() => Navigate("/editar-trabajo/" + info.idTrabajo)} size='small'>Editar</Button>

                                : <Button variant="contained" color='azul' className='boton-editar' onClick={() => Navigate("/trabajo-finalizado/" + info.idTrabajo)} size='small'>Ver</Button>}


                        </Grid>
                    </Grid>

                ))}
            </Grid>
        </Grid>
    )
}
