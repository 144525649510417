import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import Webcam from "react-webcam";
import { BrowserMultiFormatReader } from "@zxing/browser";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';


import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { Header } from '../components/Header';
import { getEditarTrabajo } from '../services/apis';
import { finalizarTrabajo } from '../services/apis';
import { Notificacion } from '../components/Notificacion';



export const EditarTrabajo = () => {
    const param = useParams();
    const Navigate = useNavigate();

    const [notificacion, setNotificacion] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [severity, setSeverity] = useState("success");

    const [finalizar, setFinalizar] = useState(false);
    const [articulos, setArticulos] = useState([]);
    const [horas, setHoras] = useState(0);
    const [articulo, setArticulo] = useState(
        {
            nombre: "Nuevo articulo",
            // codBarras: codBarras,
            // cantidad: cantidad
        });
    const [cantidad, setCantidad] = useState(1);
    const [codBarras, setCodBarras] = useState("");
    const [infoTrabajo, setInfoTrabajo] = useState([]);


    const [camara, setCamara] = useState(false);
    const [barcode, setBarcode] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const webcamRef = useRef(null);



    const handleClose = () => {
        setFinalizar(false);
    };
    const activarNotificacion = () => {
        // setMensaje(texto);
        // Cambiar la notificación a true
        setNotificacion(true);

        // Después de 2 segundos, volverla a false
        setTimeout(() => {
            setNotificacion(false);
        }, 3000);
    };

    const cambiarCantidad = (art, event, index) => {
        let tempCantidad = Number(event.target.value);

        if (tempCantidad < 0) {
            tempCantidad = 0;
        } else {
            let temp = [...articulos];

            if (index !== -1) {
                const nuevoProducto = { ...art, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
        }
    };


    const añadirArticulo = (articulo, cantidad, codBarras) => {
        cantidad = parseInt(cantidad);
        if (codBarras !== "") {
            setArticulos((prevArticulos) => {
                // Buscar si ya existe un artículo con el mismo codBarras
                const articuloExistente = prevArticulos.find((item) => item.codBarras === codBarras);

                if (articuloExistente) {
                    // Si ya existe, sumar la cantidad
                    return prevArticulos.map((item) =>
                        item.codBarras === codBarras
                            ? { ...item, cantidad: item.cantidad + cantidad } // Actualizar cantidad
                            : item
                    );
                } else {
                    // Si no existe, añadir el nuevo artículo
                    const nuevoArticulo = { ...articulo, cantidad, codBarras };
                    return [...prevArticulos, nuevoArticulo];
                }
            });
            setCantidad(1);
            // setMensaje("Articulo añadido");
            // setSeverity("success")
            // activarNotificacion();

        } else {
            // setMensaje("Introduzca un codigo de barras");
            // setSeverity("error")
            // activarNotificacion();
        }

    };

    const sumarCantidad = (art, index) => {
        let tempCantidad = art.cantidad + 1;


        if (tempCantidad < 0) {
            tempCantidad = 0;
        } else {
            let temp = [...articulos];

            if (index !== -1) {
                const nuevoProducto = { ...art, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
            console.log(tempCantidad)
        }
    };

    const restarCantidad = (art, index) => {
        let tempCantidad = art.cantidad - 1;


        if (tempCantidad < 0) {
            tempCantidad = 0;
        } else {
            let temp = [...articulos];

            if (index !== -1) {
                const nuevoProducto = { ...art, cantidad: tempCantidad };
                temp[index] = nuevoProducto;
            }
            setArticulos(temp);
            console.log(tempCantidad)
        }
    };

    const handledOnChange = (event) => {
        // console.log(event.target.name)
        // setCantidad(event.target.value);
        switch (event.target.name) {
            case "barcode":
                setCodBarras(event.target.value);
                break;
            case "horas":
                if (event.target.value <= 0) {
                    setHoras(0);
                    break;
                } else {
                    setHoras(event.target.value);
                    break;
                }

            case "cantidad":
                setCantidad(event.target.value);
                break;
            default:
                break;
        }
    };

    // Configuración de la cámara
    const videoConstraints = {
        facingMode: "environment", // Usar la cámara trasera
    };

    // Método para capturar la imagen de la cámara
    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            scanBarcode(imageSrc); // Intentar escanear el código de barras
        }
    }, [webcamRef]);

    // Método para escanear el código de barras
    const scanBarcode = async (imageSrc) => {
        setIsScanning(true);
        const reader = new BrowserMultiFormatReader();
        try {
            const result = await reader.decodeFromImageUrl(imageSrc);
            setBarcode(result.getText());
        } catch (err) {
            console.error("Error escaneando el código de barras:", err);
            setBarcode(null);
        }
        setIsScanning(false);
    };

    const getTrabajo = async (idTrabajo) => {
        try {
            const response = await getEditarTrabajo(idTrabajo);
            console.log("Respuesta", response)
            setArticulos(response.data)
            setInfoTrabajo(response.info[0])

        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }

    }

    const finTrabajo = async (idTrabajo, articulos, horas) => {
        try {
            if (horas === 0) {
                setMensaje("Introduzca cantidad de horas empleadas");
                setSeverity("error")
                setFinalizar(false)
                activarNotificacion();
            } else {
                console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", articulos)

                const response = await finalizarTrabajo(idTrabajo, articulos, horas);
                console.log("Respuesta", response)
                Navigate("/trabajos")
            }

        } catch (error) {
            console.log("Error al añadir a la Cesta")
        }

        try {

        } catch (error) {
            console.error("Error inesperado")
        }

    }




    useEffect(() => {
        getTrabajo(param.idTrabajo);
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            className='load'
        >
            <Header></Header>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={11} md={8}
                className='borde-redondo sombra-corta'
                padding={2}
                gap={1}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    md={11}
                >
                    <Grid item xs={11} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <GroupAddIcon sx={{ fontSize: 50, m: 1 }} color='naranja' />
                        <Typography variant="h4" style={{ textAlign: 'left' }}>
                            Editar Trabajo Nº: {param.idTrabajo}
                        </Typography>
                    </Grid>

                    {/* <div className="separador"></div> */}

                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            {infoTrabajo.nombre}
                        </Typography>
                        {/* <Typography variant="h6" style={{ textAlign: 'left' }}>
                            Creado: {infoTrabajo.fecha}
                        </Typography>
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Creado por: {infoTrabajo.idUsuario}
                        </Typography> */}
                    </Grid>


                    <div className="separador"></div>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    md={11}
                    spacing={1}
                >
                    <Grid item xs={12} >
                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                            Articulos usados
                        </Typography>
                        <Grid item xs={3} md={1} >
                            <div className="separador-azul" style={{ margin: '0.5em 0em' }}></div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="start"
                        md={5.5}
                        gap={1}
                        style={{ minHeight: '50vh', maxHeight: '60vh', overflow: 'auto', padding: '1em' }}
                    // className='fondo-azul borde-redondo sombra-interior'
                    >
                        {articulos.map((info, index) => (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                md={12}
                                gap={2}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    md={12}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="h5" style={{ textAlign: 'left' }}>
                                            {info.nombre == '' ? 'Articulo' : info.nombre}
                                        </Typography>
                                        <Typography variant="h6" style={{ textAlign: 'left' }}>
                                            Cod: {info.codBarras}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    md={12}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        md={12}
                                    >

                                        <Grid item xs={3} md={2}>
                                            <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
                                                Cantidad:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} md={3}>
                                            <Tooltip title="Quitar 1" arrow>
                                                <IconButton>
                                                    <RemoveIcon color="gray"
                                                        onClick={() => restarCantidad(info, index)}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={5} md={4}>
                                            <TextField id="outlined-basic" label="Cantidad" variant="outlined" type='number'
                                                value={info.cantidad}
                                                size="small"
                                                onChange={(event) => cambiarCantidad(info, event, index)}
                                                color="azul" />

                                        </Grid>
                                        <Grid item xs={2} md={3}>
                                            <Tooltip title="Añadir 1" arrow>
                                                <IconButton>
                                                    <AddIcon color="gray"
                                                        onClick={() => sumarCantidad(info, index)}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div className="separador"></div>
                            </Grid>

                        ))}


                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        md={5.5}
                        gap={1}
                    // style={{ maxHeight: '50vh', overflow: 'auto', padding: '1em' }}
                    // className='fondo-blanco borde-redondo sombra-interior'
                    >
                        <Grid item xs={12} >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Añadir articulo:
                            </Typography>
                            <Grid item xs={3} md={1}>
                                <div className="separador-azul" style={{ margin: '0.5em 0em' }}></div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button color='azul' variant="outlined" size="large" fullWidth onClick={() => camara ? setCamara(false) : setCamara(true)} style={{ margin: '1em 0em', padding: '0.7em' }}>
                                <CameraAltIcon color='azul' />&nbsp;{camara ? 'Cerrar camara' : 'Abrir camara'}
                            </Button>
                        </Grid>
                        <Collapse in={camara}>
                            {camara ? <div>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                    style={{ width: "100%", height: "auto" }}
                                    className='borde-redondo'
                                />
                                <Button color='azul' variant="contained" size="large" fullWidth onClick={capture} style={{ padding: '0.7em' }}> <QrCodeScannerIcon fontSize='large' />&nbsp;Escanear codigo </Button>

                                <div className="separador-2" style={{ margin: '1em 0em' }}></div>
                            </div>
                                : ''}

                        </Collapse>


                        <FormControl sx={{ width: '100%' }} variant="outlined">
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Codigo de Barras"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    autoFocus
                                    required
                                    onChange={handledOnChange}
                                    value={codBarras}
                                    name='barcode'
                                />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Cantidad"
                                    variant="outlined"
                                    color='azul'
                                    type='number'
                                    name='cantidad'
                                    value={cantidad}
                                    onChange={handledOnChange}
                                />

                            </Grid>
                            <Button
                                color='azul'
                                variant="contained"
                                size="large"
                                fullWidth
                                style={{ marginTop: '1em', marginBottom: '1em', padding: '1em' }}
                                onClick={() => añadirArticulo(articulo, cantidad, codBarras)}
                            >
                                <AddBoxIcon color='blanco' />&nbsp;Añadir articulo
                            </Button>
                        </FormControl>

                    </Grid>

                    <div className="separador-2"></div>

                    <div className="separador"></div>
                    <Grid item xs={12} md={12}>
                        <Grid item xs={12} >
                            <Typography variant="h5" style={{ textAlign: 'left' }}>
                                Horas empleadas:
                            </Typography>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '1em' }}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Horas"
                                variant="outlined"
                                color='azul'
                                type='number'
                                name='horas'
                                value={horas}
                                onChange={handledOnChange}
                                inputProps={{ step: 0.25 }}
                            />

                        </Grid>
                        {/* <Button variant="contained" size="large" fullWidth style={{ marginTop: '1em' }}> Añadir horas</Button> */}

                        <div className="separador"></div>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                        <Button variant="outlined" size="large" fullWidth className='boton-header' onClick={() => Navigate("/")} style={{ minHeight: '6vh', fontSize: 'large' }}> Cancelar </Button>
                    </Grid> */}
                    < Grid item xs={12} md={12} >
                        <Button color='azul' variant="contained" size="large" fullWidth className='boton-general' onClick={() => setFinalizar(true)}> Finalizar trabajo </Button>
                    </Grid>
                    <div className="separador-2"></div>
                </Grid>


            </Grid>



            {/* ---------------------------------------------------------------------------NOTIFICACIONES Y DIALOGO----------------------------------------------------------------------------- */}

            <Notificacion notificacion={notificacion} mensaje={mensaje} severity={severity}></Notificacion>


            <Dialog
                open={finalizar}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className='fondo-blanco'
                >
                    {"Finalizar Trabajo"}
                </DialogTitle>
                <DialogContent
                    className='fondo-blanco'
                >
                    <DialogContentText id="alert-dialog-description">
                        Desea finalizar la orden de trabajo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    className='fondo-blanco'
                >
                    <Button onClick={handleClose} color='azul' size="medium" variant="outlined">Cancelar</Button>
                    <Button onClick={() => finTrabajo(param.idTrabajo, articulos, horas)} autoFocus color='azul' variant="contained" size="medium">
                        Finalizar Trabajo
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
